import { Modal } from 'ant-design-vue'
import { h } from 'vue'
import '@/styles/domain-custome-modal.scss'
import { logEvent, matchPageTypeData } from '@/utils/gio'
import { t } from '@/utils/i18n-util'
// 类型定义
interface DomainConfig {
  oldDomain?: string
  newDomain?: string
  modalTitle?: string
  modalContent?: any
  modalOkText?: string
  closable?: boolean
}

interface GioEventData {
  pageNameID_var: string
  buttonName_var?: string
}

// 常量配置
const ENV_PREFIXES = ['dev.', 'sit.', 'uat.', 'pre.'] as const

const DEFAULT_MODAL_CONFIG = {
  newDomain: 'siemens-x.com.cn',
  closable: true,
  width: 800,
  centered: true,
  maskClosable: false,
} as const

// 工具函数
const removeButtonFocus = () => {
  const okButton = document.querySelector('.ant-btn-primary')
  if (okButton) {
    setTimeout(() => {
      (okButton as HTMLElement).blur()
    }, 0)
  }
}

const createModalContent = (protocol: string, targetDomain: string, pathAndQuery: string) => {
  return h('div', { class: 'modal-content' }, [
    h('div', t('domainRedirect.content.greeting')),
    h('p', { style: { marginTop: '16px' } }, [
      t('domainRedirect.content.messageStart'),
      h('a', {
        onClick: () => {
          logEvent('positonButtonClick', {
            pageNameID_var: matchPageTypeData(window.location.pathname, window.location.href)?.id || window.location.pathname || '-',
            buttonName_var: t('domainRedirect.tracking.buttonClick')
          })
          window.location.href = `${protocol}${targetDomain}${pathAndQuery}`
        },
      }, `${protocol}${targetDomain}${pathAndQuery}`),
      t('domainRedirect.content.messageEnd')
    ])
  ])
}

export function checkAndRedirectDomain(config: DomainConfig): boolean {
  const currentDomain = window.location.hostname
  const {
    oldDomain = currentDomain,
    newDomain = DEFAULT_MODAL_CONFIG.newDomain,
    modalTitle = t('domainRedirect.header.title'),
    modalContent,
    modalOkText = t('domainRedirect.button.visitNow'),
    closable = DEFAULT_MODAL_CONFIG.closable
  } = config

  // 提前返回，避免不必要的处理
  if (!currentDomain.includes(oldDomain) || currentDomain.includes(newDomain)) {
    return false
  }

  // URL 相关处理
  const prefix = ENV_PREFIXES.find(prefix => currentDomain.startsWith(prefix)) || ''
  const targetDomain = `${prefix}${newDomain}`
  const protocol = `${window.location.protocol}//` + (!prefix ? 'www.' : '')
  const pathAndQuery = `${window.location.pathname}${window.location.search}`
  const href = `${window.location.href}`
  const pathname = `${window.location.pathname}`

  // Modal 配置
  Modal.info({
    ...DEFAULT_MODAL_CONFIG,
    title: modalTitle,
    content: modalContent || createModalContent(protocol, targetDomain, pathAndQuery),
    okText: modalOkText,
    closable,
    class: 'custom-modal',
    icon: null,
    onOk: () => {
      logEvent('positonButtonClick', {
        pageNameID_var: matchPageTypeData(window.location.pathname, window.location.href)?.id || window.location.pathname || '-',
        buttonName_var: t('domainRedirect.tracking.buttonVisit')
      })
      window.location.href = `${protocol}${targetDomain}${pathAndQuery}`
    },
    onCancel: () => {
      logEvent('positonButtonClick', {
        pageNameID_var: matchPageTypeData(window.location.pathname, window.location.href)?.id || window.location.pathname || '-',
        buttonName_var: t('domainRedirect.tracking.buttonClose')
      })
    }
  })
  removeButtonFocus()
  return true
}