import HttpRequest from '@/plugins/http'

const request = new HttpRequest({
  baseURL: '/marketplace-product',
})

const axios = request.request()

/**
 * 产品/方案详细信息
 * @export
 * @param {Object} params -
 * @param {number} [params.x_product_id] -
 * @returns
 */
export async function productDetail (params) {
  params.params.terminalChannel = 'web'
  return axios.get('/product/detail/v1', params)
}

/**
 * 白皮书详情获取
 * @export
 * @param {Object} params -
 * @param {number} [params.x_whritepaper_id] -
 * @returns
 */
export async function whitePaperDetail(params) {
  return axios.post('/whitepaper/detail/v1', params)
}

/**
 * 产品/方案信息列表
 * @returns
 */
export async function productList (params) {
  params.params.terminalChannel = 'web'
  return axios.get('/product/list/v1', params)
}

/**
 * 匹配产品/方案列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function productMatch(params) {
  return axios.post('/product/match/v1', params)
}

/**
 * 相关产品/方案列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function productRelated(params) {
  return axios.post('/product/related/v1', params)
}

/**
 * 首页产品/方案列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function productHighlight(params) {
  return axios.post('/product/highlight/v1', params)
}

/**
 * 相关客户案例列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function productRelatedArticle(params) {
  return axios.post('/product/related/article/v1', params)
}

/**
 * 产品API信息列表
 * @export
 * @param {Object} params -
 * @param {number} [params.pageNum] -
 * @param {number} [params.pageSize] -
 * @param {number} [params.orderByColumn] -
 * @param {number} [params.isAsc] -
 * @param {number} [params.x_product_id] -
 * @param {number} [params.x_topics] -
 * @param {number} [params.x_markets] -
 * @returns
 */
export async function productAPIList(params) {
  return axios.get('/product_api/list/v1', params)
}

/**
 * 产品API接入点列表
 * @export
 * @param {Object} params -
 * @param {number} [params.pageNum] -
 * @param {number} [params.pageSize] -
 * @returns
 */
export async function productAPIEndpointList(params) {
  return axios.post('/product_api_endpoint/list/v1', params)
}
/**
 * 产品API接入点详情
 * @export
 * @param {Object} params -
 * @param {number} [params.x_api_endpoint_id] -产品API接入点id
 * @returns
 */
export async function productAPIEndpointDetail(params) {
  return axios.get('/product_api_endpoint/detail/v1', params)
}

/**
 * 产品API信息详情
 * @export
 * @param {Object} params -
 * @param {number} [params.x_api_id] -产品APIid
 * @returns
 */
export async function productAPIDetail(params) {
  return axios.get('/product_api/detail/v1', params)
}

/**
 * 问答列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function faqList(params) {
  return axios.post('/faq/list/v1', params)
}

/**
 * 问答详情
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function faqDetail(params) {
  return axios.post('/faq/detail/v1', params)
}

/**
 * 全文检索产品，解决方案信息
 * @export
 * @returns
 */
export async function productSearch(params) {
  return axios.post('/product/search/v1', params)
}

/**
 * 文章信息详情
 * @export
 * @param {Object} params -
 * @param {number} [params.x_article_id] -
 * @returns
 */
export async function articleDetail(params) {
  return axios.get('/article/detail/v1', params)
}
/**
 * 三方新闻资讯信息详情
 * @export
 * @param {Object} params -
 * @param {number} [params.x_article_id] -
 * @returns
 */
export async function newsInformationDetail(params) {
  return axios.get('/news/article/detail/v1', params)
}

/**
 * 获取文章评论信息
 * @export
 * @param {Object} params -
 * @param {number} [params.x_article_id] -
 * @returns
 */
export async function articlesComment(params) {
  return axios.get('/articles/comment/v1', params)
}

/**
 * 文章信息列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function articlesList(params) {
  return axios.post('/articles/list/v1', params)
}

/**
 * 合作伙伴信息详情
 * @export
 * @param {Object} params -
 * @param {number} [params.id] -
 * @returns
 */
export async function partnerDetail(params) {
  return axios.get('/partner/detail/v1', params)
}

/**
 * 合作伙伴信息列表
 * @export
 * @param {Object} params -
 * @param {number} [params.pageNum] -
 * @returns
 */
export async function partnerList(params) {
  return axios.post('/partner/list/v1', params)
}

/**
 * 产品目录列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function categoriesList(params) {
  return axios.post('/product/categories/list/v1', params)
}

/**
 * 课程专题列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function courseTopics(params) {
  return axios.post('/course/topics/v1', params)
}

/**
 * 课程视频列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function courseList(params) {
  return axios.post('/course/list/v1', params)
}

/**
 * 课程视频详情
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function courseDetail(params) {
  return axios.post('/course/detail/v1', params)
}

/**
 * 课程专题详情
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function courseRopicDetail(params) {
  return axios.post('/course/topic/detail/v1', params)
}

/**
 * 伙伴相关产品列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function partnerRelatedOffering(params) {
  return axios.post('/product/partnerRelatedOffering/v1', params)
}

/**
 * 解决方案关联伙伴
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function solutionRelatedPartners(params) {
  return axios.post('/solution/related/partners/v1', params)
}

/**
 * 产品/方案分组列表
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function marketProductList(params) {
  return axios.post('/product/list/group/v1', params)
}

/**
 * 收藏夹链接是否有效
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function collIsvalid(params) {
  return axios.post('/product/object/isvalid', params)
}

/**
 * 分享生成二维码
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function shareCreateQrcode(params) {
  return axios.post('/qrcode/gen/v1', params)
}

/**
 * 获取数碳未来栏目视频
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function getColumnVideoList(params) {
  return axios.get('/dcfuture/getColumnVideoList', params)
}

/**
 * 获取数碳未来产品/方案数据
 * @export
 * @returns
 */
export async function getDcFutureProductData() {
  return axios.get('/dcfuture/getDcFutureProductData')
}

/**
 * 获取数碳未来白皮书
 * @export
 * @param {JSON} params - 查看swagger
 * @returns
 */
export async function getWhiterPaperList(params) {
  return axios.get('/dcfuture/getWhiterPaperList', params)
}

// 获取短链接详情
export async function getShortLinkDetail(params) {
  return axios.get('/qrcode/getShortLinkDetail', params)
}

// 获取合作伙伴和相关成功案例
export async function getPartnersAndSuccessCase(params) {
  return axios.post('/solution/related/partners/v2', params)
}

// 获取获取栏目白皮书
export async function getColumnWhitePaper(params) {
  return axios.post('/column/content/white-paper/list', params)
}

// 获取获取栏目视频
export async function getColumnVideo(params) {
  return axios.post('/column/content/video/list', params)
}

// 获取获取栏目offering数据
export async function getColumnOfferingData(params) {
  return axios.post('/column/content/product/list', params)
}
/**
 * pageType信息
 * @export
 * @param {Object} params
 * @returns
 */
export async function getPageTypeData(params) {
  return axios.post('/page/type/list/v1', params)
}

// 获取首页banner
export async function getHomePageBanner() {
  return axios.get('/banner/list?category=home')
}

// 获取新版首页成功案例
export async function getHomePageArticles(params) {
  return axios.post('/articles/list/v2', params)
}

// 获取新版首页X元素
export async function getHomePageX() {
  return axios.post('/home/x/element/list')
}

// 获取新版合作伙伴tab标签
export async function getCategoriesForNewHome(params) {
  return axios.post('/article/categories/list/v1', params)
}

// 获取可持续发展用户配置的内容数据
export async function getColumnContentList(params) {
  return axios.post('/column/content/list', params)
}

// 获取可持续发展用户配置的内容数据
export async function getDefinitionFormDetail(params) {
  return axios.post('/definitionForm/get/detail', params)
}

// 根据商品skuid获取对应协议列表
export async function getTermListBySkuids(params) {
  return axios.post('/terms/record/check', params)
}

// 获取职位
export async function getPositionData() {
  return axios.get('/dict/position/list')
}

// 法律条款列表获取
export async function getLawList(params) {
  return axios.post('/terms/legal/catalogue/list', params)
}
// 产品/案例统计
export async function getproductStatisticsGroup(params) {
  return axios.post('/product/statistics/group/v1', params)
}
// 获取客户成功案例
export async function getCostomerCasesList(params) {
  return axios.post('/article/list/v1', params)
}
export async function getTopicAndIndustry(params) {
  return axios.post('/product/categories/list/v1', params)
}

// 获取SSO所需的UIM Token
export async function getUimToken (params) {
  return axios.post('product/detail/getUimToken', params)
}

// 频道列表
export const getCatalogList = async () => axios.get('/catalog/simple/list')

// 关注的频道列表
export const getFocusCatalog = async () => axios.get('/catalog/focus/list')

// 关注频道
export const focusCatalog = async (catalogIds) => axios.post('/catalog/focus', { catalogIds })

// 频道详情
export const getCatalogDetail = async (catalogId) => axios.get(`/catalog/${catalogId}/detail`)

// 频道商品专区
export const getCatalogProductZone = async (catalogId) => axios.get(`/catalog/${catalogId}/product-zone/list`)

// 频道店铺专区
export const getCatalogMerchantList = async (catalogId, params) => axios.get(`/catalog/${catalogId}/merchant`, { params })

// 全部店铺
export const getAllMerchantList = async (params) => axios.get('/catalog/merchant/list', { params })

// 店铺详情
export const getShopDetail = async (params) => axios.post('/shop/renovation/detail', params)

// 批量查询商品
export const getOfferingProductList = async (params) => axios.post('/offeringProduct/list', params)

// 批量查询案例
export const getArticleList = async (params) => axios.post('/article/list', params)