import { createStore } from 'vuex' // 导入createStore构造函数
import { getCartCount } from '@/api/store'
import { urlQueryToObject } from '@/utils/utils'
import seo from './seo'

export default createStore({
  state: {
    // Vuex的状态，实际上就是存数据的地方
    lang: '',
    login: false,
    fixed: false,
    routeNameData: [], // 页面路由对应的中英文名称
    positionZhList: {}, // 中文职位
    positionEnList: {}, // 英文职位
    demoStatus: {
      show: false,
      type: '',
      accountInfo: {},
      fn: () => {},
    },
    menuExpand: false,
    shoppingCartNumber: 0, // 购物车数量
    orderConfirmData: {}, // 订单确认数据--用于用户点击立即购买及前往结算时页面跳转时的数据传递
    topicAndVerticalId: '',
    isMyOrderDetail: false,
    ecItem: {},
    invoiceEcItem:{},
    endUserEcItem:{},
    actNavItemKey: '',
  },
  getters: {
    // 提供获取Vux状态的方式, 注意在组件中调用时getPerson是以属性的方式被访问
  },
  mutations: {
    // 提供直接操作Vuex的方法，注意mutations里的方法中不能有任何异步操做
    setActNavItemKey(state, value) {
      // 第一个参数state为Vuex状态；第二个参数为commit函数传来的值
      state.actNavItemKey = value
    },
    setLang(state, value) {
      // 第一个参数state为Vuex状态；第二个参数为commit函数传来的值
      state.lang = value
    },
    setLoginStatus(state, value) {
      // 第一个参数state为Vuex状态；第二个参数为commit函数传来的值
      state.login = value
    },
    setFixed(state, value) {
      // 第一个参数state为Vuex状态；第二个参数为commit函数传来的值
      state.fixed = value
    },
    setRouteNameData(state, value) {
      state.routeNameData = value
    },
    setDemoStatus(state, value) {
      state.demoStatus = value
    },
    setMenuExpand(state, value) {
      state.menuExpand = value
    },
    setShoppingCartNumber(state, value) {
      state.shoppingCartNumber = value
    },
    setOrderConfirmData(state, value) {
      state.orderConfirmData = value
    },
    setTopicAndVerticalId(state, value) {
      state.topicAndVerticalId = value
    },
    setIsMyOrderDetail(state, value) {
      state.isMyOrderDetail = value
    },
    setEcItem(state, value) {
      state.ecItem = value
    },
    setInvoiceEcItem(state,value) {
      state.invoiceEcItem = value
    },
    setEnduserEcItem(state,value) {
      state.endUserEcItem = value
    },
    setPositionZhList (state, value) {
      state.positionZhList = value
    },
    setPositionEnList(state, value) {
      state.positionEnList = value
    },
  },
  actions: {
    // 提供通过mutations方法来简介操作Vuex的方法
    // 获取购物车数量
    changeCartCountData(context) {
      getCartCount({}).then((res) => { // TO-DO
        if (res.code == 200) {
          context.commit('setShoppingCartNumber', res?.data || 0)
        }
      })
    },
  },
  modules: {
    seo // 在这里注册seo模块
  }
})
